import { Link } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import MillionCard from "../../superpod/OnemillionCard"
import Buttons from "../button"
import EbookScard from "./EBookCards"
import "./View-cards.scss"

const BlogCard = props => {
  const {
    coverImage,
    tags,
    title,
    excerpt,
    author,
    readTime,
    slug,
  } = props.data
  return (
    <Link to={`/blog/${slug}`}>
      <div className={`Blog-cards theme-${props.theme}`}>
        <div className="cover-image">
          <img className="w-100" src={coverImage.url} alt="blog-img" />
        </div>
        <div className="cover-card position-relative">
          <div className="categories a14">
            {tags.map((tag, tagIdx) => (
              <>
                <Link
                  className="link"
                  to={`/blog/categories/${tag.toLowerCase()}`}
                >
                  {tag}
                </Link>
                <span
                  className="line"
                  style={{ display: tagIdx === tags.length - 1 && "none" }}
                >
                  |
                </span>
              </>
            ))}
          </div>
          <div
            className={`details ${
              props.cardSize === "Large" ? "p16" : "altv2 p14"
            }`}
          >
            <h4 className="title">{title}</h4>
            <p className="excerpt">{excerpt}</p>
          </div>

          <div className="d-flex justify-content-between position-absolute bottom-0 author-time a14 p14">
            <div>
              <Link className="author" to={`/blog/author/${author.slug}/`}>
                {author.name}
              </Link>
            </div>
            <div>
              <p className="read-time">{readTime}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

const ViewCards = props => {
  const { data, colLg, theme, isViewMoreAvailable } = props

  let episode = data[0]?.type === "Audio" || data[0]?.type === "Video"

  let blog = data[0]?.isBlog === "Yes"

  let ebook = episode === blog

  const [blogsToBeShown, setBlogsToBeShown] = useState(6)
  const [episodesToBeShown, setEpisodesToBeShown] = useState(6)
  const [ebooksToBeShown, setEbooksToBeShown] = useState(6)

  let totalPosts = data.length

  return (
    <div className="viewable-cards d-flex flex-column">
      <Row style={{ rowGap: "20px" }}>
        {data.map((el, idx) => (
          <Col
            lg={colLg ?? 4}
            className={`${props.contentCenter && "justify-content-center"}`}
            style={
              isViewMoreAvailable &&
              ((blog && {
                display: idx < blogsToBeShown ? "flex" : "none",
              }) ||
                (episode && {
                  display: idx < episodesToBeShown ? "flex" : "none",
                }) ||
                (ebook && {
                  display: idx < ebooksToBeShown ? "flex" : "none",
                }))
            }
          >
            {blog && (
              <BlogCard data={el} theme={theme} cardSize={props.cardSize} />
            )}
            {episode && (
              <>
                <MillionCard
                  data={el}
                  width_100
                  slug={
                    el.season === "S1"
                      ? el.type === "Audio"
                        ? "superpod-podcast"
                        : "superpod-bytes"
                      : "season2"
                  }
                  isFeaturedEpisode={props.featuredEpisodeCard}
                />
              </>
            )}
            {ebook && (
              <EbookScard
                data={el}
                prefixSlug={props.ebookPrefixSlug}
                theme={theme}
                ebookMaxWidth="320px"
              />
            )}
          </Col>
        ))}
      </Row>

      {isViewMoreAvailable &&
        ((totalPosts <= 6 ||
          (blog && blogsToBeShown) ||
          (episode && episodesToBeShown) ||
          (ebook && ebooksToBeShown)) >= totalPosts ? null : (
          <div className="d-flex justify-content-center">
            <Buttons
              theme={props.theme === "dark" ? "secondary white" : "primary lg"}
              text="VIEW MORE"
              onClick={() =>
                (blog && setBlogsToBeShown(blogsToBeShown + 3)) ||
                (episode && setEpisodesToBeShown(episodesToBeShown + 3)) ||
                (ebook && setEbooksToBeShown(ebooksToBeShown + 3))
              }
            />
          </div>
        ))}
    </div>
  )
}

export default ViewCards
