import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import Frame from "../components/common/frame"
import ViewCards from "../components/common/View-Cards/View-cards"
import Navigation from "../components/navigation"
import "../styles/pages/road-to-1-m.scss"

function RoadTo1Million() {
  let buttonTypes = ["Read", "Listen", "Download"]
  const [activeTab, setActiveTab] = useState("Read")

  const [dataToBeSend, setDataToBeSend] = useState()

  const changeTabBtn = btn => {
    setActiveTab(btn)
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query RoadToOneMillion {
            SuperOps {
              pageComponents(where: { page: Road_To_1_Million }) {
                featuredItem {
                  ... on SuperOps_Post {
                    id
                    title
                    excerpt
                    author {
                      id
                      name
                      slug
                    }
                    coverImage {
                      id
                      url(
                        transformation: {
                          document: { output: { format: webp } }
                          image: { resize: { width: 516 } }
                        }
                      )
                    }
                    tags
                    readTime
                    slug
                    isBlog
                  }
                  ... on SuperOps_Episode {
                    episodeNumber
                    image {
                      url(
                        transformation: {
                          document: { output: { format: webp } }
                        }
                      )
                    }
                    title
                    summary {
                      html
                    }
                    whatYouWillLearn {
                      html
                    }
                    highlights {
                      html
                    }
                    embedded {
                      html
                    }
                    guestName
                    guestTitle
                    audioFile {
                      url
                    }
                    audioDuration
                    type
                    superPodGuestTextTheme
                    slug
                    season
                  }
                  ... on SuperOps_Resource {
                    id
                    category
                    resourcetitle: title
                    resourceimage: image {
                      url
                    }
                    resourceslug: slug
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    id
                  }
                }
                hero {
                  heading {
                    html
                    text
                  }
                  content {
                    html
                    text
                  }
                  primaryButtonText
                  backgroundImage {
                    url
                  }
                  heroImage {
                    url
                  }
                  formCtaText {
                    text
                  }
                }
                ctaBoxes {
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  primaryButtonText
                }
                episodes {
                  episodeNumber
                  season
                  image {
                    url(
                      transformation: { document: { output: { format: webp } } }
                    )
                  }
                  title
                  summary {
                    html
                  }
                  whatYouWillLearn {
                    html
                  }
                  highlights {
                    html
                  }
                  embedded {
                    html
                  }
                  guestName
                  guestTitle
                  audioFile {
                    url
                  }
                  audioDuration
                  type
                  superPodGuestTextTheme
                  slug
                  season
                }
                posts {
                  id
                  title
                  excerpt
                  author {
                    id
                    name
                    slug
                  }
                  coverImage {
                    id
                    url(
                      transformation: {
                        document: { output: { format: webp } }
                        image: { resize: { width: 516 } }
                      }
                    )
                  }
                  tags
                  readTime
                  slug
                  isBlog
                }
                resources {
                  title
                  chapterNumber
                  excerpt {
                    text
                  }
                  image {
                    url
                  }
                  slug
                }
              }
            }
          }
        `}
        render={data => (
          <div
            className="Road-to-1-million-page"
            style={{ background: "#0e0e0e" }}
          >
            {data.SuperOps.pageComponents.map(pageComponent => {
              const {
                seo,
                hero,
                featuredItem,
                ctaBoxes,
                posts,
                episodes,
                resources,
              } = pageComponent
              return (
                <>
                  <Frame seo={seo} theme="dark" footerBgColor="#0e0e0e">
                    <header>
                      <Navigation
                        page="RoadToMillion"
                        transparentScroll
                        // navBgDuration="0.5s"
                        dskScroll={140}
                        mobScroll={140}
                      />
                    </header>

                    <section
                      className="hero"
                      style={{
                        backgroundImage: `url(${hero.backgroundImage.url})`,
                      }}
                    >
                      <Container className="Layout-container">
                        <Row className="justify-content-center altv1">
                          <Col
                            lg={6}
                            className="d-flex flex-column justify-content-center"
                          >
                            <img
                              className="hero-img mx-auto"
                              src={hero.heroImage.url}
                              alt="img"
                            />
                            <h4 className="gradient-text-32 mx-auto">
                              {hero.heading.text}
                            </h4>
                            <div className="p16 content mx-auto">
                              <p>{hero.content.text}</p>
                            </div>
                            <FormBuilder
                              formClassName="hero-form"
                              errorClassName="error-message"
                              inputClassName="email"
                              inputErrorClassName="error-active"
                              buttonText="SUBSCRIBE"
                              buttonClassName="primary white"
                              type="subscribe"
                              path="subscription"
                            />
                            <div className="p16 cta-text mx-auto">
                              {/* <p>Subscribe to stay updated on the same.</p> */}
                              <p>{hero.formCtaText.text}</p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="featured-items">
                      <Container className="Layout-container">
                        <Row style={{ rowGap: "24px" }}>
                          <Col md={7}>
                            <ViewCards
                              data={[featuredItem[0]]}
                              colLg={12}
                              theme="dark"
                              isViewMoreAvailable={true}
                              cardSize="Large"
                            />
                          </Col>
                          <Col
                            md={5}
                            className="episode-ebook d-flex flex-column"
                          >
                            <ViewCards
                              data={[featuredItem[1]]}
                              colLg={12}
                              theme="dark"
                              isViewMoreAvailable={true}
                              featuredEpisodeCard
                            />

                            <div className="Download-card-wrapper">
                              <div className="image-wrapper">
                                <img
                                  src={featuredItem[2].resourceimage.url}
                                  className="ebook-image w-100"
                                  alt="ebookNewletter2"
                                  style={{ height: "100%" }}
                                />
                              </div>

                              <div className="content-wrapper position-relative alt">
                                <p className="highlight">
                                  {featuredItem[2].category}
                                </p>

                                <h6>{featuredItem[2].resourcetitle}</h6>
                                <div className="position-absolute w-100 bottom-0">
                                  <Link
                                    to={`/resources/templates/${featuredItem[2].resourceslug}`}
                                  >
                                    <Buttons
                                      style={{
                                        width: "100%",
                                        height: "35px",
                                        padding: "11px 24px",
                                      }}
                                      theme="secondary white"
                                      text="DOWNLOAD NOW"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="view-cards-section">
                      <Container className="Layout-container">
                        <h4 className="heading">EVERYTHING ELSE</h4>
                        <div className="btn-div">
                          {buttonTypes.map(el => (
                            <Buttons
                              className="tab-button"
                              text={el}
                              onClick={() => changeTabBtn(el)}
                              active={el === activeTab}
                            />
                          ))}
                        </div>
                        <ViewCards
                          data={
                            activeTab === "Read"
                              ? posts
                              : activeTab === "Listen"
                              ? episodes
                              : resources
                          }
                          theme="dark"
                          isViewMoreAvailable={true}
                          contentCenter
                          ebookPrefixSlug="resources/templates"
                        />
                      </Container>
                    </section>

                    <section className="ctaBox">
                      <CTA
                        data={ctaBoxes}
                        lgLeft={4}
                        lgRight={6}
                        buttonText="SUBSCRIBE"
                        className="Layout-container"
                      />
                    </section>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}
export default RoadTo1Million
