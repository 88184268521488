import React from "react"
import { Link } from "gatsby"
import "./EBookCards.scss"

const EbookCard = props => {
  const { title, chapterNumber, excerpt, slug, image } = props.data
  return (
    <div
      className={`Ebook-card-wrapper ${props.theme ?? "light"}`}
      style={{ maxWidth: props.ebookMaxWidth ?? "unset" }}
    >
      <Link to={`/${props.prefixSlug && props.prefixSlug + "/"}${slug}`}>
        <div className="e-book-card">
          <div className="position-relative overflow-hidden img-wrapper">
            <img src={image?.url} className="ebook-img w-100" alt="img" />
          </div>
          <div className="content-wrapper">
            <p className="title">{title}</p>
            <p className="excerpt">{excerpt?.text}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default EbookCard
